/**
 * Choose Your Region Modal component.
 *
 * @author Vladimir Shishlyannikov
 * @created 2024/09/12
 * @copyright 2024-present iWoo Ltd. All rights reserved.
 */

import React, { useEffect, useState, useCallback, useRef } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import * as Sentry from "@sentry/browser";
import Cookies from "js-cookie";

// Components
import Button from "../Button/Button";

// Hooks
import useTheme from "../ThemeProvider/useTheme/useTheme";

// Utils
import { clientWidth } from "../../utils";
import { modalStyles } from "../../constants";

// Icons
import ArrowRightIcon from "../../icons/ArrowRightIcon";

// Images
import Image from "./ChooseYourRegionImage";
import usaFlag from "./usa-flag.png";
import usaFlag2x from "./usa-flag@2x.png";
import usaFlag3x from "./usa-flag@3x.png";
import ukFlag from "./uk-flag.png";
import ukFlag2x from "./uk-flag@2x.png";
import ukFlag3x from "./uk-flag@3x.png";

const Checkbox = ({ children, className, id, name, value, onChange }) => {
  const handleChange = React.useCallback(
    (e) => {
      onChange && onChange(!value);
    },
    [value]
  );

  return (
    <div className={className}>
      <input
        id={id}
        type="checkbox"
        name={name}
        disabled={false}
        className="tb-checkbox-new_"
        checked={value}
        onChange={handleChange}
      />
      <label
        htmlFor={id}
        tabIndex="0"
        className="tbk-text-small tbk-mb-0 tbk-flex tbk-h-3 tbk-items-center tbk-pl-4 tbk-text-primary"
      >
        {children}
      </label>
    </div>
  );
};

const selectedRegion = Cookies.get("region-selected");

export const RegionSelectModal = ({ isOpen, onClose }) => {
  const { theme } = useTheme();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [usaCheckbox, setUsaCheckbox] = useState(selectedRegion !== "GB");
  const [ukCheckbox, setUkCheckbox] = useState(selectedRegion === "GB");

  const modalResolveRef = useRef();

  const openModal = () => {
    return new Promise((resolve) => {
      modalResolveRef.current = resolve;

      setIsModalOpen(true);
    });
  };

  const closeModal = () => {
    if (modalResolveRef.current) {
      modalResolveRef.current();
      modalResolveRef.current = null;

      onClose && onClose();
    }

    setIsModalOpen(false);
  };

  useEffect(() => {
    let regionSelectedCookie = Cookies.get("region-selected");

    if (!regionSelectedCookie) {
      fetch("https://api.db-ip.com/v2/free/self")
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            return Promise.reject(res);
          }
        })
        .then((data) => {
          if (data.error || !isRegionMatch(data.countryCode)) {
            openModal();
          }
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "production") {
            Sentry.captureMessage(
              `Fetching IP geolocation failed :-S. ${err.statusText}`
            );
            Sentry.captureException(err);
          }

          // If failed to determine geolocation, and there's no cookie
          // open the modal
          openModal();

          console.log(`Fetching IP geolocation failed :-S. ${err.statusText}`);

          return Promise.reject(err);
        });
    } else if (!isRegionMatch(regionSelectedCookie)) {
      openModal();
    }
  }, []);

  useEffect(() => {
    if (isOpen) {
      const selectedRegion = Cookies.get("region-selected");

      setUsaCheckbox(selectedRegion !== "GB");
      setUkCheckbox(selectedRegion === "GB");

      openModal();
    } else if (!isOpen) {
      closeModal();
    }
  }, [isOpen]);

  const handleUsaCheckboxChange = () => {
    setUsaCheckbox(true);
    setUkCheckbox(false);
  };

  const handleUkCheckboxChange = () => {
    setUkCheckbox(true);
    setUsaCheckbox(false);
  };

  const handleContinueClick = () => {
    // Remember for 3 days
    var date = new Date();

    date.setDate(date.getDate() + 3);

    Cookies.set("region-selected", ukCheckbox ? "GB" : "US", {
      path: "/",
      expires: date,
    });

    if (usaCheckbox && !isRegionMatch("US")) {
      // window.location.href = "http://trube.app";
      window.location.href = process.env.REACT_APP_US_WEBSITE_PUBLIC_URL;
    } else if (ukCheckbox && !isRegionMatch("GB")) {
      window.location.href = process.env.REACT_APP_UK_WEBSITE_PUBLIC_URL;
    } else {
      closeModal();
    }
  };

  const defaultCustomStyle =
    clientWidth() < theme.responsive.breakpoints.sm
      ? Object.assign(
          {},
          {
            ...modalStyles.newMobileDefault,
          },
          {
            overlay: {
              ...modalStyles.newMobileDefault.overlay,
              zIndex: 1060,
            },
          }
        )
      : Object.assign(
          {},
          {
            ...modalStyles.default,
          },
          {
            overlay: {
              ...modalStyles.default.overlay,
              zIndex: 1060,
            },
            content: {
              ...modalStyles.default.content,
              width: "698px",
              padding: "32px",
            },
          }
        );

  return (
    <Modal
      appElement={document.getElementById("#app")}
      isOpen={isModalOpen}
      // onAfterOpen={afterOpenModal}
      // onRequestClose={this.closeModal}
      style={defaultCustomStyle}
      contentLabel="Choose Your Region Modal"
    >
      {/*<Link role='button' onClick={this.closeModal}><Icon name='close' className='tb-pd-0' /></Link>*/}
      <div className="choose-your-region-modal tbk-rounded-lg tbk-bg-basic-white">
        <div className="tbk-items-center_ tbk-justify-center md:tbk-flex">
          <div className="tbk-my-4 tbk-px-4 tbk-text-center">
            <Image className="tbk-inline-block" />
          </div>
          <div className="tbk-my-4 tbk-px-4 md:tbk-mt-4 md:tbk-mb-4 md:tbk-pl-4 md:tbk-pr-4">
            <h2 className="tbk-text-title-bold md:tbk-text-h2-black tbk-mb-0.5 tbk-text-primary lg:tbk-whitespace-nowrap lg:!tbk-leading-tight">
              Choose Your Region
            </h2>
            <p className="tbk-text-main tbk-mb-4 tbk-text-blue-grey">
              We have different services in each country.
            </p>

            <Checkbox
              id="ukCheckbox"
              className="tbk-mb-0.5 tbk-p-1"
              value={ukCheckbox}
              onChange={handleUkCheckboxChange}
            >
              <div className={`tbk-flex tbk-justify-center `}>
                <img
                  src={ukFlag}
                  srcSet={`${ukFlag} 1x, ${ukFlag2x} 2x, ${ukFlag3x} 3x`}
                  alt="GB"
                  style={{ marginRight: "4px" }}
                />
                <div className="tbk-text-main tbk-mb-0 tbk-text-primary">
                  United Kingdom
                </div>
              </div>
            </Checkbox>

            <Checkbox
              id="usaCheckbox"
              className="tbk-mb-4 tbk-p-1"
              value={usaCheckbox}
              onChange={handleUsaCheckboxChange}
            >
              <div className={`tbk-flex tbk-items-center `}>
                <img
                  src={usaFlag}
                  srcSet={`${usaFlag} 1x, ${usaFlag2x} 2x, ${usaFlag3x} 3x`}
                  alt="USA"
                  style={{ marginRight: "4px" }}
                />
                <div className="tbk-text-main tbk-mb-0 tbk-text-primary">
                  United States
                </div>
              </div>
            </Checkbox>

            <Button
              variant={"secondary"}
              className="tbk-w-full"
              endIcon={<ArrowRightIcon />}
              onClick={handleContinueClick}
            >
              Continue
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

RegionSelectModal.propTypes = {
  isOpen: PropTypes.string,
  onClose: PropTypes.func,
};

const isRegionMatch = (regionCode) =>
  regionCode === process.env.REACT_APP_REGION;

export default RegionSelectModal;
