import React from "react";
// import moment from "moment";

// Utils
import * as dates from "../../../utils/dates";
import { getMomentWithTz } from "../../../utils/date";

const renderHeaders = ({ week, format, highlighted, culture }) => {
  var firstOfWeek = 0; // TODO: detect with moment

  return week.map((date) => {
    return (
      <th
        className={`tb-head-cell tbk-text-small-bold tbk-pb-1.5 tbk-text-center tbk-uppercase ${
          dates.eq(date, highlighted, "day")
            ? "tbk-text-coral"
            : "tbk-text-blue-grey"
        }`}
        key={"header_" + dates.weekday(date, undefined, firstOfWeek)}
      >
        {getMomentWithTz(date).format(format || "ddd" /*, culture*/)}
      </th>
    );
  });
};

export default renderHeaders;
