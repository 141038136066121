import React from "react";
// import moment from "moment-timezone";

// Components
import CalendarCellView from "../CalendarCell/CalendarCell";

// Icons
import ArrowLeftIcon from "../../../icons/ArrowLeftIcon";
import ArrowRightIcon from "../../../icons/ArrowRightIcon";

// Utils
import * as dates from "../../../utils/dates";
import { getMomentWithTz } from "../../../utils/date";

const CalendarCellBtn = ({
  isDisabled,
  isFocused,
  isSelected,
  isHighlighted,
  label,
  date,
  onMouseEnter,
  onMouseLeave,
}) => {
  return (
    <div
      className={`tbk-relative tbk-my-0 tbk-mx-auto tbk-h-5 tbk-w-5 tbk-rounded-full tbk-p-1 tbk-pt-1.5 tbk-pb-1.5 ${
        isFocused || isSelected
          ? "tbk-bg-coral tbk-text-basic-white"
          : isDisabled
          ? "tbk-text-grey-inactive"
          : `${
              isHighlighted
                ? "tbk-text-grey-main md:tbk-text-coral"
                : "tbk-text-grey-main"
            }`
      } tbk-touch-manipulation`}
      onMouseEnter={() => !isDisabled && onMouseEnter(date)}
      onMouseLeave={() => onMouseLeave(null)}
    >
      {label}
    </div>
  );
};

const renderRow = ({
  rows,
  focused,
  today,
  activeId,
  disabled,
  onChange,
  value,
  culture,
  highlighted,
  availableDays,
  min,
  max,
  footerFormat,
  dayFormat,
  prevDisabled,
  nextDisabled,
  onMoveLeft,
  onMoveRight,
  onMouseEnter,
  onMouseLeave,
}) => {
  const dateFormat = dayFormat || "DD";

  return (
    <tr role="row" className="tb-calendar-row">
      <td className="">
        <button
          type="button"
          className={`tbk-h-4 tbk-w-4 tbk-touch-manipulation tbk-rounded-full ${
            prevDisabled
              ? "tbk-cursor-disabled tbk-bg-basic-white tbk-text-grey-inactive"
              : "tbk-bg-basic-white tbk-text-blue-dark"
          }`}
          onClick={onMoveLeft}
          disabled={prevDisabled}
        >
          <ArrowLeftIcon className="tbk-inline-block" />
        </button>
      </td>
      {rows.map((date, colIdx) => {
        var formattedDate = getMomentWithTz(date).format(dateFormat);
        var label = getMomentWithTz(date).format(footerFormat || "DD MMM");

        return (
          <CalendarCellView
            key={colIdx}
            activeId={activeId}
            label={label}
            view="week"
            date={date}
            now={today}
            availableDays={availableDays}
            min={min}
            max={max}
            onChange={onChange}
            focused={focused}
            selected={value}
            disabled={disabled}
          >
            <CalendarCellBtn
              isHighlighted={dates.eq(date, highlighted, "day")}
              label={formattedDate}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
            />
          </CalendarCellView>
        );
      })}
      <td className="">
        <button
          type="button"
          className={`tbk-h-4 tbk-w-4 tbk-touch-manipulation tbk-rounded-full ${
            nextDisabled
              ? "tbk-bg-basic-white tbk-text-grey-inactive"
              : "tbk-bg-basic-white tbk-text-blue-dark"
          }`}
          onClick={onMoveRight}
          disabled={nextDisabled}
        >
          <ArrowRightIcon className="tbk-inline-block" />
        </button>
      </td>
    </tr>
  );
};

export default renderRow;
